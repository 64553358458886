<template>
  <div class="max-w-screen-xl mx-auto px-5">
    <LandingNavbar />
    <div v-if="!auth.isAuthenticated">
      <h1>Login</h1>
      <form @submit.prevent="onSubmit">
        <input type="email" v-model="email" placeholder="Email" required />
        <input type="password" v-model="password" placeholder="Password" required />
        <button type="submit">Login</button>
      </form>
      <p v-if="error">{{ error }}</p>
    </div>
    <div v-else>
      <p>You are already logged in.</p>
      <button @click="redirectToDashboard">Go to Dashboard</button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref } from 'vue';

definePageMeta({
  layout: "landing",
  colorMode: 'light',
});

interface AuthState {
  isAuthenticated: boolean;
  user: any | null;
  accessToken: string | null;
}

const { login } = useDirectusAuth();
const auth = useState<AuthState>('auth');
const router = useRouter();

const email = ref('');
const password = ref('');
const error = ref('');

const onSubmit = async () => {
  try {
    error.value = '';
    const loginResponse = await login({ email: email.value, password: password.value });
    
    auth.value = {
      isAuthenticated: true,
      user: loginResponse.user,
      accessToken: loginResponse.access_token,
    };
    
    localStorage.setItem('authState', JSON.stringify(auth.value));
    
    await router.push('/dashboard');
  } catch (e) {
    console.error(e);
    error.value = "Invalid credentials";
  }
};

const redirectToDashboard = () => {
  router.push('/dashboard');
};
</script>